const paths = require('./paths');

paths.PARENTS_ANCHOR = '#prices-for-parents';
paths.FACILITIES_ANCHOR = '#prices-for-institutions';
paths.PRIVACY_POLICY = 'privacy-policy';
paths.TOS = 'terms-of-services';
paths.FAQ = 'faqs';
paths.ASHA = 'ASHA2022';
paths.GAME = 'game';

paths.EDUCATIONAL_CORNER = 'educational-corner';
paths.DISTANCE_SPEECH_THERAPY =
  'educational-corner/remote-speech-and-language-therapy';
paths.ONLINE_SPEECH_THERAPY_EXERCISES =
  'educational-corner/online-speech-and-language-therapy';
paths.SPEECH_DISORDER = 'educational-corner/speech-disorders';

module.exports = paths;
