import * as React from 'react';

import { BlogImage, Text } from '../../components';

import { PageNew } from '..';

import BlogArticles from './blogLastArticles';

import ParentAndChild1 from '../../images/educational-corner/blog33/roditelj-s-djetetom1.jpg';
import ParentAndChild2 from '../../images/educational-corner/blog33/roditelj-s-djetetom4.jpg';
import ParentAndChild3 from '../../images/educational-corner/blog33/roditelj-s-djetetom3.jpg';
import ParentAndChild4 from '../../images/educational-corner/blog33/roditelj-s-djetetom2.jpg';

const SpeechDisorderPage = () => {
  return (
    <PageNew
      title="KOKOLINGO - Remote speech and language therapy"
      description="Remote therapy, also known as teletherapy, enables a speech and language
        therapy visit to occur through use of videoconference software like Zoom
        or Doxy rather than in a physical office or other in-person setting"
      headline="Remote speech and language therapy"
    >
      <Text as="p">
        Remote therapy, also known as teletherapy, enables a speech and language
        therapy visit to occur through use of videoconference software like Zoom
        or Doxy rather than in a physical office or other in-person setting.
        Teletherapy visits look and feel similar to live therapy visits with one
        main difference: the therapist and client are not in the same room. The
        option of teletherapy makes access to the benefits of therapy possible
        and convenient for people who live miles away from a physical speech
        language therapy clinic where services are typically provided. Most
        therapy clinics are located in bigger towns and cities, so remote
        therapy has most commonly been used to give support to people living in
        rural areas without access to local in-person services. Teletherapy
        offers flexibility to both patient and provider because it removes the
        issue of distance as well as the expense and time of travel to and from
        the clinic. It also offers everyone equal access to experts and
        specialists in any needed field. At different stages of the coronavirus
        pandemic, in-person therapy has been difficult or completely impossible
        to arrange, depending on current restrictions and closure of in-person
        service delivery models. As a result, the importance and ubiquity of
        remote speech therapy has increased dramatically over the past few
        years.
      </Text>
      <BlogImage
        imgAlt="Parent and child"
        imgTitle="Parent and child"
        imgSrc={ParentAndChild1}
      />
      <Text as="h2">EQUIPMENT NEEDED FOR TELETHERAPY </Text>
      <Text as="p">
        Remote therapy requires that the therapist and the client both use
        audiovisual equipment so that they can see and hear each other clearly.
        The participants of the video call need a stable internet connection and
        a device for establishing the call – for instance, a laptop, tablet, or
        smartphone. Many devices come with a built-in camera and microphone that
        will be sufficient for the purposes of a basic audiovisual call. For
        higher quality of picture and sound you can also upgrade to an external
        web camera and/or external microphone. Some people enjoy the use of a
        headset to pick up sound more clearly and reduce feedback issues.
        Audiovisual conferencing software like Zoom, Google Meet, or Doxy offer
        paid subscription options but also offer basic features for free. These
        free subscriptions are usually time-limited (e.g., only 40-60 minutes
        per video call before you get booted off). 40-60 minutes is
        approximately the length of a typical therapy session, so this
        restriction is not often an issue. Even if this timeframe is not long
        enough for the purposes of your call, you can immediately start another
        meeting after the first one times out. For therapists, it is important
        to investigate the encryption-level of the subscription you are
        considering. Encryption helps protect your client’s protected health
        information (PHI) from falling into the wrong hands or being leaked
        publicly. “End to end encryption” is the industry standard to safeguard
        your meeting from unwelcome visitors and protect the privacy of your
        client.
      </Text>
      <Text as="h2">REMOTE THERAPY MATERIALS</Text>
      <Text as="p">
        For teletherapy, many speech and language therapists prefer to use
        digital materials over traditional paper handouts, pictures, and other
        tangible physical items. Most digital materials will be in Word document
        or PDF form. These materials can be used in remote therapy because they
        are easily shared on the screen. In this way, the clients can see and
        hear everything on the speech and language therapist's screen and
        participate in activities, look at pictures, or read handouts together.
        These digital materials can also be emailed to the client who can review
        them further on the screen or print them out from home if that is their
        preference. Besides digital content, the therapist can also use physical
        objects (e.g., dolls, puzzles, books, etc.). They should bring the
        physical objects close to the camera and be sure that all significant
        actions are visible on the client’s screen as well as the therapist’s.
        Sometimes the speech therapist may ask their client’s caregiver to
        procure specific items, toys, and materials from their own home for use
        on the screen in a designated way. This method works well for clients
        who do not yet have a good attention span for two-dimensional
        representations on the screen. Your therapist will let you know which
        items (if any) you can prepare before your teletherapy appointment.
      </Text>
      <BlogImage
        imgAlt="Parent and child"
        imgTitle="Parent and child"
        imgSrc={ParentAndChild2}
      />
      <Text as="h2">PREPARATION FOR REMOTE THERAPY</Text>
      <Text as="p">
        Teletherapy with a child requires communication, cooperation, and
        partnership between the speech therapist and the client’s parents.
        Before starting, the parents should receive or ask for an overview of
        what therapy will look like, prepare for the technical details of the
        videocall, and prepare any needed in-home materials that the speech
        language therapist requests. Make sure you have agreed upon the
        appointment time, keeping in mind that time zones may differ when doing
        remote therapy. Plan ahead to determine where the parent should be
        during video call. If the client is a child of preschool age, it is
        advisable that the parent sit with the child in their lap. This way, the
        parent can guide the child and keep them focused on the tasks presented.
        With a school-age child, the parent can often just be nearby in case of
        technical difficulties or needed redirection of the child’s attention.
        Finding a stable location where the device can rest is a good idea; It
        is much easier to find a place to rest the device for 40 -60 minutes
        rather than planning to hold the device in your hand for that long.
        Consider locating a place where the device can stay plugged in for the
        duration of the visit, as audiovisual calls can often drain a device’s
        battery.
      </Text>
      <Text as="h2">MOTIVATORS FOR REMOTE THERAPY</Text>
      <Text as="p">
        According to speech and language therapists who work with children, one
        of the biggest issues to address in remote therapy is maintaining a
        child's concentration and motivation for work. Each child requires an
        individual approach; Some children find it easy to concentrate and feel
        motivated to engage in sedentary work, while other children find it hard
        to sit still for long periods of time and become easily distracted. Some
        parents report that working on a laptop or iPad is intrinsically
        distracting for their child because of the proximity of YouTube or other
        favorite apps. Just like in face-to-face therapy, the process of keeping
        young clients engaged in the tasks at hand requires creativity and
        planning on the part of the therapist. Many therapists will introduce
        fun videos, allow for a game, or encourage the child to get up and move
        for a few minutes in between the exercises to avoid fatigue. The
        therapist will likely retain control of the visit by screen sharing this
        fun content from his or her computer and telling the client ahead of
        time how long each break will last. These fun activities, besides being
        motivators, can also be used by a savvy therapist to continue to enhance
        speech and language skills on the sly. Skills like asking questions,
        taking turns in conversation, or telling a story are intrinsic to many
        fun activities, games, and videos. Remember, kids learn through play, so
        chances are that your child’s speech therapist is indirectly targeting
        speech and language goals even if an activity looks like a lot of fun
        and giggles!
      </Text>
      <BlogImage
        imgAlt="Parent and child"
        imgTitle="Parent and child"
        imgSrc={ParentAndChild3}
      />
      <Text as="h2">KOKOLINGO AND GOOGLE MEET</Text>
      <Text as="p">
        Kokolingo is a speech and language therapy tool for children with speech
        sound disorders. It is an ideal example of how modern speech therapy
        materials are now being created to be equally functional in both remote
        therapy and in-person therapy settings. Many preschool and school-age
        children have begun attending their speech therapy sessions remotely
        since the start of the coronavirus pandemic. As parents and children
        scrambled to find ways to attend remote school and speech therapy from
        home, therapists scrambled to locate quality screen-friendly digital
        speech therapy materials that can be used with a variety of clients.
      </Text>
      <Text as="p">
        Here is one example of how a therapist might access Kokolingo during a
        remote therapy visit using the Chrome web browser. In this example,
        Google Meet is the audiovisual platform used to share Kokolingo between
        the two computers.
      </Text>
      <Text as="p">
        First, the speech and language therapist and parent must individually
        acquaint themselves with Google Meet software. To access it, you will
        need a Google Gmail (email) account, which is completely free and
        relatively quick and painless to set up. After that, a time for therapy
        should be agreed upon, keeping in mind that different time zones may
        come into play. With your free Gmail account comes access to Google
        Calendar, which will allow the therapist to schedule and share the
        meeting time into the parent’s calendar. This strategy ensures everyone
        is on the same page for start and end time and the date of the visit.
      </Text>
      <Text as="p">
        When you click on the link that your speech therapist sent you in an
        email or from the Google calendar, it will open into a private window
        where you can check your appearance and test your sound before you are
        visible to anyone else in the meeting. When you are ready, click the
        button to enter the meeting. Once in the meeting you will have the
        option to mute/unmute your sound and turn your video on/off as desired.
        In general, it’s a good idea to keep your audio and video on for the
        entire visit. Use the volume buttons on your computer to adjust the
        volume of the other person’s speech. It’s ok to ask your speech language
        therapist to walk you through some basics of the software so you feel
        comfortable with the features before diving into the therapy visit.
      </Text>
      <Text as="p">
        It is important to stress that the free version of Google Meet allows
        the duration of the meeting to last up to one hour, but no more, so make
        sure to pay attention to time. Be mindful and begin to wind down the
        session as the end time approaches so you won’t be cut off
        mid-conversation.
      </Text>
      <Text as="p">
        More about the functioning of the Google Meet software can be found
        <a href="https://apps.google.com/intl/en/meet/how-it-works/">here</a>.
      </Text>
      <Text as="h2">SCREEN SHARING</Text>
      <Text as="p">
        The speech therapist can click a button on the bottom of the Google Meet
        screen to allow the parent and child to view whatever is visible on the
        therapist’s computer screen. This is called “screensharing.” More on how
        to do this can be found here. It is advisable for the therapist to
        prepare the windows on Chrome with needed materials pulled up and ready
        in advance. For example, consider logging in to Kokolingo and selecting
        the activity you will use first before joining the Google Meet call. In
        another window, you can open an online game or YouTube video to be used
        as a motivator. This preparation reduces downtime in the visit where a
        child might become fidgety. Preparation beforehand also keeps the entire
        visit running smoothly and maintains professional standards.
      </Text>
      <BlogImage
        imgAlt="Parent and child"
        imgTitle="Parent and child"
        imgSrc={ParentAndChild4}
      />
      <Text as="h2">SOME TIPS FOR THE BEST USER EXPERIENCE</Text>
      <Text as="p">
        In order for remote therapy to remain interactive, the speech therapist
        should still be able to see both child and parent while screen sharing
        their content. It is ideal to open the Google Meet video in one Chrome
        window and prepare Kokolingo and all other necessary games and materials
        in another. For optimal user experience from the therapist’s
        perspective, many appreciate the ease of having two monitors to make it
        easier to view all materials and switch between screens. It is also
        possible to shrink both Chrome windows to fit side by side in one
        screen. Clients don’t need to worry about multiple windows or screens,
        but only need to focus on enlarging the Google Meet video for easy
        viewing. When the therapist starts screensharing, the video the clients
        see of them will shrink or disappear. You can click and drag on the
        individual camera feeds to enlarge or shrink them as desired. It’s
        helpful particularly in speech sound therapy to enhance the visual image
        of the therapist’s face so the child can better view his or her mouth
        movements. Parents can also model and exaggerate specific mouth
        movements needed to produce a specific sound correctly.
      </Text>
      <Text as="p">
        In order for the therapy to be successful, the speech therapist and the
        parent need to communicate and cooperate well with each other. Remote
        therapy requires a period of adjustment and learning - for the therapist
        as well as the client and their caregiver. Sharing goals and concerns
        openly and being honest about how therapy is going will lead to success
        for the child in speech therapy, regardless of delivery model.
      </Text>
      <Text as="p" bold>
        Author: Krešimir Perša, Speech and Language Therapist
      </Text>
      <Text as="p">
        Disclaimer: Videoconference materials can be used both for therapeutic
        and personal purposes. Before you start using a particular software for
        therapeutic purposes, be sure you are abiding by all laws, codes, and
        regulations (HIPPA, privacy laws, end-to-end encryption).
      </Text>
      <BlogArticles />
    </PageNew>
  );
};

export default SpeechDisorderPage;
