import React from 'react';
import { Link } from 'gatsby';
import { Localize } from '@kokolingo/localisation';
import routes from '../../routes';

export default [
  {
    question: <Localize id="FAQ.Question1" />,
    answer: <Localize id="FAQ.Answer1" />,
  },
  {
    question: <Localize id="FAQ.Question2" />,
    answer: <Localize id="FAQ.Answer2" />,
  },
  {
    question: <Localize id="FAQ.Question3" />,
    answer: (
      <Localize
        id="FAQ.Answer3"
        values={{
          Link: (linkText) => (
            <Link
              className="faq-page-block__container__answer__link"
              to={routes.SPEECH_DISORDER}
              title="Articulatory disorders"
              hrefLang="en-uk"
            >
              {linkText}
            </Link>
          ),
        }}
      />
    ),
  },
  {
    question: <Localize id="FAQ.Question4" />,
    answer: <Localize id="FAQ.Answer4" />,
  },
  {
    question: <Localize id="FAQ.Question5" />,
    answer: <Localize id="FAQ.Answer5" />,
  },
  {
    question: <Localize id="FAQ.Question6" />,
    answer: <Localize id="FAQ.Answer6" />,
  },
  {
    question: <Localize id="FAQ.Question7" />,
    answer: <Localize id="FAQ.Answer7" />,
  },
  {
    question: <Localize id="FAQ.Question8" />,
    answer: <Localize id="FAQ.Answer8" />,
  },
  {
    question: <Localize id="FAQ.Question9" />,
    answer: <Localize id="FAQ.Answer9" />,
  },
  {
    question: <Localize id="FAQ.Question10" />,
    answer: <Localize id="FAQ.Answer10" />,
  },
  {
    question: <Localize id="FAQ.Question11" />,
    answer: (
      <Localize
        id="FAQ.Answer11"
        values={{
          Link: (linkText) => (
            <Link
              className="faq-page-block__container__answer__link"
              to={routes.SPEECH_DEVELOPMENT}
              title="Speech development"
              hrefLang="en-uk"
            >
              {linkText}
            </Link>
          ),
        }}
      />
    ),
  },
  {
    question: <Localize id="FAQ.Question12" />,
    answer: <Localize id="FAQ.Answer12" />,
  },
  {
    question: <Localize id="FAQ.Question13" />,
    answer: (
      <Localize
        id="FAQ.Answer13"
        values={{
          Link: (linkText) => (
            <Link
              className="faq-page-block__container__answer__link"
              to={routes.LANGUAGE_DEVELOPMENT}
              title="Language and speech development"
              hrefLang="en-uk"
            >
              {linkText}
            </Link>
          ),
        }}
      />
    ),
  },
  {
    question: <Localize id="FAQ.Question14" />,
    answer: <Localize id="FAQ.Answer14" />,
  },
  {
    question: <Localize id="FAQ.Question15" />,
    answer: <Localize id="FAQ.Answer15" />,
  },
  {
    question: <Localize id="FAQ.Question16" />,
    answer: <Localize id="FAQ.Answer16" />,
  },
  {
    question: <Localize id="FAQ.Question17" />,
    answer: <Localize id="FAQ.Answer17" />,
  },
  {
    question: <Localize id="FAQ.Question18" />,
    answer: <Localize id="FAQ.Answer18" />,
  },
  {
    question: <Localize id="FAQ.Question19" />,
    answer: (
      <Localize
        id="FAQ.Answer19"
        values={{
          a: (linkText) => (
            <a
              className="faq-page-block__container__answer__link"
              href="https://app.kokolingo.hr/settings/subscriptions"
              title="Payment settings"
              hrefLang="en-uk"
            >
              {linkText}
            </a>
          ),
        }}
      />
    ),
  },
  {
    question: <Localize id="FAQ.Question20" />,
    answer: <Localize id="FAQ.Answer20" />,
  },
];
