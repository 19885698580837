import React from 'react';
import { Link } from 'gatsby';

import routes from '../../routes';

export default [
  <Link to={routes.SPEECH_DISORDER} hrefLang="en-uk">
    What are Speech Sound Disorders?
  </Link>,

  <Link to={routes.DISTANCE_SPEECH_THERAPY} hrefLang="en-uk">
    Remote speech and language therapy
  </Link>,

  <Link to={routes.ONLINE_SPEECH_THERAPY_EXERCISES} hrefLang="en-uk">
    Online speech and language therapy
  </Link>,
];
