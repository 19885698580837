import * as React from 'react';

import { BlogImage, Text } from '../../components';

import { PageNew } from '..';

import BlogArticles from './blogLastArticles';

import TherapistWorksWithChildren from '../../images/educational-corner/blog28/Logoped-radi-s-djecom.jpg';
import TherapistExercisesWithChild from '../../images/educational-corner/blog28/Logoped-vjezba-izgovor-glasa-s-djetetom.jpg';
import ChildPlays from '../../images/educational-corner/blog28/gusarska-gimnastika.jpg';

const SpeechDisorderPage = () => {
  return (
    <PageNew
      title="KOKOLINGO - What are Speech Sound Disorders?"
      description="Speech sound disorders are diagnosed when a child retains these developmental
          speech errors beyond the expected age. Some kids need extra time and
          repetition practicing challenging speech sounds before they get it
          just right"
      headline="What are Speech Sound Disorders?"
    >
      <Text as="p">
        A gift that most of us take for granted in life is the effortless flow
        of fluent speech. Fluent speech allows us to communicate with one
        another comfortably, effectively, and unselfconsciously. The tongue,
        teeth, lips, and jaw (a group of body parts collectively referred to as
        “the articulators” by speech language therapists) must work together in
        flawless choreography to connect sounds to form even the simplest of
        words. Take the short word “cat,” as an example. To speak this word
        aloud, we must almost-but-not-entirely close our jaw, pull back our
        tongue, push up our soft palate to block the nasal passage, and push the
        right amount of air gently and consistently out from our lungs.. just to
        form the /k/ sound that starts the word!! The subsequent /a/ in the
        middle of “cat” then requires us to adjust the degree of jaw closure to
        make our jaw a little wider – but not too wide, push our tongue flat to
        the bottom of our mouth, and provide more breath support. To form the
        final /t/ sound, our tongue must next travel quickly forward to the
        front of the mouth to touch behind the upper teeth with precise pressure
        and duration, and the jaw must again adjust its angle. Only when this
        sequence is performed with exact precision does the intended message
        come across. All of these precise fine motor movements need to be
        completed in the span of less than a second, and without error. Speech
        requires, therefore, complex muscle memory. Now take two, three, four,
        or more simple short words and string them together in a phrase or
        sentence, and you have performed a dizzying synchronism of muscle
        movement, a literal tongue twister, with effortless grace. Like almost
        anything else in life, this effortless skill is born from uncountable
        hours of practise.
      </Text>
      <Text as="h2">The Very Beginnings of Speech Sound Exploration</Text>
      <Text as="p">
        Most of the time, babies will start imitating sounds that are visible on
        our lips (called “visemes” by your speech therapist) such as P, B, and M
        before any other sounds. Because these sounds are visible, speakers are
        unintentionally giving new speakers (aka: babies) visual as well as
        auditory clues about how to formulate and imitate these specific sounds.
        Babies must practise very basic repeated sequences of movement (e.g.:
        bababababa”) before they learn to begin mixing up the sequences more and
        more (e.g.: “bahbidu”). Babbling is baby’s first articulation practise!
      </Text>
      <BlogImage
        imgAlt="Therapist works with children"
        imgTitle="Therapist works with children"
        imgSrc={TherapistWorksWithChildren}
      />
      <Text as="p">
        Sound exploration and experimentation intrinsic to babbling is an
        important keystone in the development of speech and language skills for
        every baby. It is a definite concern if your baby is not making sounds
        and babbling every day by 6-8 months. To promote babbling, incorporate
        music into your daily routines, practise vocalising back and forth with
        your little one with a sound as simple as “ahh,” and show them how to be
        noisy with their mouth by bouncing the back of your hand over your mouth
        repeatedly to make a “wahwahwah” sound or using a finger to part their
        lips and make silly sounds.
      </Text>
      <Text as="h2">Practise Makes Progress</Text>
      <Text as="p">
        Even after we start trying to talk and say specific sounds in words, our
        little mouths need lots of practise before they are reliably able to
        coordinate the long sequences of sound needed for human communication.
        The older children get, the more complex their thinking becomes, and
        they may attempt to express themselves in longer sentences. Thus, kids
        with a lot to say and a hard time coordinating the movements needed to
        say it quickly can become very frustrated. There is no connection
        between intelligence and speech sound disorders. Although there can be a
        comorbidity between speech disorders, language disorders, and literacy
        disorders, the presence of a speech disorder does not reflect in any way
        the intelligence of the individual.
      </Text>
      <Text as="p">
        All children make mistakes with speech sounds when they are first
        learning to talk. Expected errors like leaving final sounds off
        (e.g.”ba” instead of “ball”), avoiding sounds made in the throat (e.g.:
        “tootie” for “cookie”), reducing clusters of consonant sounds (e.g.:
        “sip” for “slip”), and having trouble pronouncing English’s more
        challenging speech sounds like “r, s,” or “th” are all normal, natural,
        and expected stages of learning to talk. Speech sound disorders are
        diagnosed when a child retains these developmental speech errors beyond
        the expected age. Some kids need extra time and repetition practising
        challenging speech sounds before they get it just right. In speech
        therapy, and with Kokolingo, they begin by practising the isolated
        target sound by itself, then in small but ever-growing words, phrases,
        and then sentences.
      </Text>
      <BlogImage
        imgAlt="Therapist exercising with child"
        imgTitle="Therapist exercising with child"
        imgSrc={TherapistExercisesWithChild}
      />
      <Text as="p">
        Speech sound errors sound cute when kids are teeny tiny, but as they
        grow older, speech sound disorders can contribute to a lot of
        frustration for a child when listeners cannot commonly understand them
        and they must habitually repeat themselves or resort to wild gestures to
        be understood. As previously stated, speech disorders are not connected
        in any way to intelligence level or cognitive skill, meaning that many
        kids who have a hard time expressing themselves may have a lot of
        interesting things to say! These kids can heartbreakingly learn to
        altogether avoid situations where they believe they may have to speak.
        This avoidance protects them from the embarrassment and shame that can
        accompany speech sound errors in a social setting.
      </Text>
      <Text as="h2">Articulation and Phonological Disorders</Text>
      <Text as="p">
        We can break speech sound disorders into 2 different classifications:
        articulation disorders and phonological disorders. Each of these
        classifications is unique in concept, diagnosis, and treatment.
        Articulation disorders refer to challenges with specific speech sounds
        on an individual basis (e.g.: the notorious /r/ sound that so many
        middle schoolers struggle with). With an articulation disorder, the
        child’s other speech sounds are unaffected, and the focus is on one or
        more unrelated sound errors. Phonological disorders, in contrast,
        contain identifiable and predictable patterns of related speech sound
        errors.
      </Text>
      <Text as="p">
        This might look like a pattern of always producing throat sounds (/k,
        g/) in the front of the mouth instead of in the throat. This particular
        phonological process is called “fronting” by your speech therapist. The
        pattern of fronting is manifested in the classic mispronunciation of
        asking for a “tootie” instead of a “cookie.” A child with this
        phonological process would also be expected to front other throat sounds
        like the /g/ sound in “go,” which might come out as “doe” instead.{' '}
      </Text>
      <Text as="h2">What Causes Speech Sound Disorders?</Text>
      <Text as="p">
        The cause of speech sound disorders can be quite varied and is sometimes
        unknown. Some possible causes may be structural anatomical differences
        (for example when dentition is incomplete or abnormal - loose tooth,
        anyone? - or with a diagnosis like cleft palate), physiological
        differences (as in dysarthria), motor difficulties (as in the cases of
        apraxia, stroke, or cerebral palsy), or sensory (as in the case of
        hearing loss). The cause of the difficulty in pronunciation is important
        to identify whenever possible. If the cause is structural, the priority
        may be to fix the structure with orthotics or surgery before any amount
        of speech therapy can resolve the problem further. If the cause of the
        speech sound disorder is related to any degree of hearing loss, speech
        therapy will not help much until and if the child’s hearing status
        improves.
      </Text>
      <BlogImage
        imgAlt="Child plays"
        imgTitle="Child plays"
        imgSrc={ChildPlays}
      />
      <Text as="h2">
        What is the Treatment Plan for Speech Sound Disorders?
      </Text>
      <Text as="p">
        Your child’s speech therapist can make an individualised plan to correct
        speech sound errors. Multisensory learning is usually incorporated into
        therapeutic practise, including visual, auditory, and tactile cues.
        Usually choosing the easiest error to correct first will give your child
        a boost of confidence with their communication and can also have
        spill-over effects into other identified speech sound errors. Your
        therapist will help your child learn to make the sound on its own, then
        introduce practise that requires more and more sequencing in longer
        words, phrases, sentences, and paragraphs. Your child will be ready to
        graduate from speech therapy when they can produce the target sound
        correctly during conversational connected speech. The total amount of
        time that treatment takes varies greatly among clients and may depend on
        a variety of factors including: number and type of errors, age and
        motivation of the client, resolution of the cause of the error(s), and
        frequency, focus, and length of speech therapy visits. The
        accomplishment of correcting a speech sound error reflects hours of
        concerted, intentional practise and effort. You may observe a new social
        and conversational self-assurance in your child when they have learnt to
        speak with more confidence and skill. Kokolingo can help you and your
        child along the way on this journey!
      </Text>
      <Text as="p" bold>
        Teal Radford- M.A. CCC-SLP
      </Text>
      <BlogArticles />
    </PageNew>
  );
};

export default SpeechDisorderPage;
