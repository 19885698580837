import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { Tag, Sounds } from '../../components';

export default () => {
  return (
    <Sounds>
      <Row>
        <Col>
          {['CH', 'F', 'G', 'J', 'K', 'L', 'R', 'S', 'SH', 'TH', 'V', 'Z'].map(
            (sound) => (
              <Tag key={sound}>{sound}</Tag>
            )
          )}
        </Col>
      </Row>
      <Row>
        <Col className="col-auto">
          <Tag>L Blends</Tag>
        </Col>
        <Col className="d-md-block">
          {['BL', 'FL', 'GL', 'KL', 'PL'].map((sound) => (
            <Tag variant="light" key={`LBlends${sound}`}>
              {sound}
            </Tag>
          ))}
        </Col>
      </Row>
      <Row>
        <Col className="col-auto">
          <Tag>R Blends</Tag>
        </Col>
        <Col className="d-md-block">
          {['BR', 'DR', 'FR', 'GR', 'KR', 'PR', 'TR'].map((sound) => (
            <Tag variant="light" key={`RBlends${sound}`}>
              {sound}
            </Tag>
          ))}
        </Col>
      </Row>
      <Row>
        <Col className="col-auto">
          <Tag>S Blends</Tag>
        </Col>
        <Col className="d-md-block">
          {['SK', 'SL', 'SM', 'SN', 'SP', 'ST', 'SW'].map((sound) => (
            <Tag variant="light" key={`SBlends${sound}`}>
              {sound}
            </Tag>
          ))}
        </Col>
      </Row>
    </Sounds>
  );
};
