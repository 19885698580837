import React from 'react';

export default () => (
  <>
    <div
      style={{ marginBottom: '5%', marginTop: '5%' }}
      className="container h4"
    >
      <h1 style={{ marginBottom: '5%' }}>Privacy Policy - TODO</h1>
    </div>
  </>
);
